import React, { useRef, useState, useLayoutEffect } from "react"
import { Link } from "gatsby"

import "./style.scss"

const NewButton = ({
  label,
  to,
  onClick,
  type,
  disabled,
  color,
  className,
  state,
  stickyScroll = false
}) => {
  const isExternalURL =
    to && !!to.includes("https://") && !!to.includes("http://")

  let buttonClass = 'new-button-v3 text-v3'

  if (className) buttonClass += ` ${className}`
  if (color) buttonClass += ` col--${color}`

  const stickyRef = useRef()
  const [sticky, setSticky] = useState(false)
  const banner = typeof document !== "undefined" ? document.querySelector(".discount-banner") : null

  useLayoutEffect(() => {
    if (!stickyScroll) {
      return
    }

    // Leave this console.log in, as for some reason the sticky functionality doesn't work without it
    const handleScroll = () => {
      const buttonOffset = document.getElementById('button')?.offsetTop
      console.log(window.scrollY);
      if (buttonOffset > 0 && window.scrollY > 480) {
        setSticky(true)
      } else {
        setSticky(false)
      };
    };



    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonClass]);

  if (type || disabled) {
    return (
      <button
        type={type}
        className={buttonClass}
        disabled={disabled}
        onClick={onClick}
      >
        {label}
      </button>
    )
  }

  if (to && !isExternalURL)
    return (
      <Link className={buttonClass} to={to} onClick={onClick} state={state}>
        {label}
      </Link>
    )

  return (
    <>
      <button id="button" className={sticky ? `${buttonClass} sticky-v3 ${banner ? "include-banner-height" : ""}` : buttonClass} ref={stickyRef} onClick={onClick}>
        {label}
      </button>
      {sticky ? <div
        style={{
          position: "fixed",
          left: '0%',
          top: '0%',
          width: '100%',
          height: `2px`,
          zIndex: '100',
          backgroundColor: '#464ca7'
        }}
      /> : null}
    </>
  )
}

export default NewButton
