import { Link } from "gatsby"
import React, {useEffect, useRef, useState} from "react"
import WebpImage from "../WebpImage"

import './style.scss'
import useIsHomePageV2 from '../../utils/useIsHomePageV2'
import Slider from "react-slick";
import {reviewsData} from "./reviews";
import SliderPagination from "../SliderPagination";

const HomeCtaAvg = () => {
  const isHomePageV2 = useIsHomePageV2()
  const sliderRef = useRef()
  const [currentPage, setCurrentPage] = useState(1)

  const beforeChange = (prev, next) => {
    setCurrentPage(next + 1)
  }

  return (
    <div className={`home-cta-avg ${isHomePageV2 ? 'v2' : ''}`}>
      <div className="home-cta-avg--background">
        <WebpImage wrapperClassName="laptop-image" className="laptop-image" fileName="Pages/Homepage/Hubble-Contacts-400-Million-Lenses-Sold-Desktop.jpg" />
        <WebpImage wrapperClassName="mobile-image" className="mobile-image" fileName="Pages/Homepage/Hubble-Contacts-400-Million-Lenses-Sold-Mobile.jpg" />
      </div>
      <div className="grid-section">
        <h2>Over 400 million lenses sold.</h2>
        <Slider arrows={false} className="reviews-container" ref={sliderRef} beforeChange={beforeChange}>
          {reviewsData.map(({author, message, reviewRating}, index) => (
            <div className="review-card" key={index}>
              <p className="message">{message}</p>
              <div className="details">
                <Stars grade={reviewRating}/>
                <p className="author">—{author}</p>
              </div>
            </div>
          ))}
        </Slider>
        <SliderPagination sliderRef={sliderRef} currentPage={currentPage} allPages={reviewsData.length}/>
        <a href="#order-section" className="btn btn-primary cta-btn">
          Start Your Subscription
        </a>
      </div>
    </div>
  )}

const Stars = ({grade}) => (
    <div className="stars-container" data-grade={grade}>
      {Array(Number(5)).fill().map((_, i) => (
        <WebpImage wrapperClassName="star-icon" key={i} fileName="Icons/star.svg"/>
      ))}
    </div>
  )

export default HomeCtaAvg
