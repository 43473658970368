import React, {useContext} from 'react';
import WebpImage from '../../WebpImage';
import { usePromo, promoStart, promoEnd } from "../../../utils/usePromo";
import './styles.scss';
import ProductTag from '../../ProductTag';
import {lensesData} from "./lensesData";
import HubbleOrderContext from "../../../context/hubbleOrderContext";
import TestVariant from "../../TestVariant";
import scrollTo from "gatsby-plugin-smoothscroll";

const ContactsCharacteristics = ({ isAffiliatePage, isV3, isAvg }) => {
  const isPromo = usePromo(promoStart, promoEnd)
  const { setBrandSelected, setBrand: setContextBrand, setTaxes, setShippingAddress} = useContext(HubbleOrderContext)

  const lenses = [
    {
      img: isV3 ? "Classic-Product-Image.png" : "hubble.png",
      title: isV3 ? "Hubble Classic" : "Hubble",
      description: "Our classic daily lens — it’s affordable, convenient, and comfortable for all-day wear.",
      price: `<strike>$22.99/eye</strike><span> $1.00</span></br>for your first order`,
      type: "classic",
    },
    {
      img: isAffiliatePage ? "hydro-characteristics.png" : isV3 ? "Sign-Up-Hydro-Product-Image.png" : "hydro.png",
      title: "Hydro by Hubble",
      description: "With innovative packaging, Hydro by Hubble is naturally hydrating and has a smooth lens edge.",
      price: "<strike>$24.99/eye</strike> <span>$4.99/eye</span> </br>80% off your first order",
      type: "hydro",
      labelText: "Best Value",
      tagText: "Popular Choice",
      tagColor: "purple"
    },
    {
      img: isAffiliatePage ? "skyhy-characteristics.png" : isV3 ? "Sign-Up-SkyHy-Product-Image.png" : "skyhy.png",
      title: "SkyHy by Hubble",
      description: "As our most breathable lens, SkyHy by Hubble is made from silicone hydrogel, the material preferred by doctors.",
      price: "<strike>$29.99/eye</strike> <span>$9.99/eye</span> </br>66% off your first order",
      type: "skyhy",
      tagText: "Premium Pick",
      tagColor: "orange"
    }
  ]
  const lenseList = isAffiliatePage ? lenses.filter(({ description }) => !description.toLowerCase().includes('classic')) : lenses

  const handleSelectBrand = (title) => {
    scrollTo("#order-section")
    setProduct(lensesData(isPromo).find(e => e.title.toLowerCase().includes(title.toLowerCase())))
  }

  const setProduct = (value) => {
      setContextBrand(value);
      setTaxes(0)
      setShippingAddress({
        first_name: "",
        last_name: "",
        address1: "",
        address2: "",
        city: "",
        province: "",
        zipcode: "",
        phone: "",
      })

      if (isAvg) {
          setTimeout(() => {
          const section = document.querySelector( '#prescription' );
          section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }, 500)
      } else {
          setTimeout(() => {
          const section = document.querySelector( '#order-section' );
          section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
          }, 100)
      }


    // google datalayer events for select item
    window.dataLayer.push({ecommerce: null})
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [
          {
            item_id: value.cc_product_id || '2167100637257',
            index: 0,
            item_name: value.title,
            currency: "USD",
            discount: 0,
            item_brand: value.title.includes("Hubble") ? "Hubble" : value.title,
            item_category: "contacts",
            item_category2: 'spherical',
            item_category3: 'N/A', //gender
            item_category4: 'N/A',
            item_category5: 'N/A',
            item_list_id: "Normal",
            item_variant: 'N/A',
            price: parseFloat(value.sale_price)
          }
        ]
      }
    })


  }

  return (
    <section className={`grid-section characteristics-landing-v3 ${isAffiliatePage ? "affiliate" : ""} ${isV3 ? "v3" : ""} ${isAvg ? "avg" : ""}`} id="lenses-section">
      <h2 className="characteristics-title-v3 avg-signup">{"Hubble Daily \nContact Lenses"}</h2>
      {isAvg &&
        <div className="content-container">
          <div className="characteristics-subtitle-avg">
            <span className="text">{"Hubble Daily \nContact Lenses"}</span>
          </div>
        </div>
      }
      <div className="content-container-contacts-v3">
        {lenseList.map(({ img, title, description, price, type, tagText, tagColor, labelText }) => {
          const isHydroSkyHy =
            title.includes("Hydro") || title.includes("SkyHy")
          return (
            <div className={`item-block ${isPromo ? "promo" : ""}`} data-type={type} onClick={() => handleSelectBrand(title)}>
              {(type === "hydro" || type === "skyhy") &&
                <div className="top-banner" data-type={type === "hydro" ? "hydro" : "skyhy"}>
                  New reduced price
                </div>
              }
              <div className={`image-wrapper ${isPromo ? "promo" : ""}`}>
                <div>
                  <WebpImage
                    wrapperClassName="image"
                    className="image"
                    fileName={`Pages/Intake/${img}`}
                  />
                </div>
                {(!isAffiliatePage && type !== "classic" && isPromo) && <ProductTag text={type === "hydro" && "80%\n OFF" || type === "skyhy" && "66%\n OFF"} label={isPromo} isPromo={isPromo} />}
                {(!isAffiliatePage && tagText) && <ProductTag text={tagText} color={tagColor} />}
              </div>

              <div className="text-block">
                <div>
                  <h2 className="lenses-title">{title}</h2>
                  <p className="description">{description}</p>
                </div>
                <div className='lenses-wrapper' data-type={type}>
                  {(!isAffiliatePage && labelText && !isPromo) && <ProductTag text={labelText} color='dark-purple' label />}
                  <p
                    className="lenses-price"
                    dangerouslySetInnerHTML={{ __html: price }}
                  ></p>
                </div>
                {isPromo && type !== "classic" && <p className="subtitle">now through june 4</p>}
              </div>
              {isAffiliatePage ?
                <a href="#" className="start-button">
                  {`Shop ${title.includes("Hydro")
                    ? "Hydro"
                    : title.includes("SkyHy")
                      ? "SkyHy"
                      : ""
                    } By Hubble`}
                </a>
                : <div className="start-button" onClick={() => handleSelectBrand(title)}>
                  Buy Now
                </div>
              }
            </div>
          )
        })}
      </div>
    </section>
  )
};

export default ContactsCharacteristics;
