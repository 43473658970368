import useTest from "../../utils/useTest"

const TestVariant = ({testName, variant, children}) => {
  const _variant = useTest(testName)
  const isActive = _variant === variant

  return isActive && children ? children : null
}

export default TestVariant
