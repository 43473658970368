export const lensesData = (isPromo) => [
  {
    value: "hubble",
    percent_off: "0",
    link: "",
    title: "Hubble Classic Lenses",
    image: "",
    price: "22.99",
    sale_price: "1.00",
    cc_product_id: "hubble",
    days: "30",
    quantity: 30
  },
  {
    value: "hydro",
    percent_off: "0",
    link: "",
    title: "Hydro by Hubble Contact Lenses",
    short_title: "Hydro By Hubble",
    image: "",
    price: "24.99",
    sale_price: "4.99",
    cc_product_id: "hydro",
    days: "30",
    quantity: 30
  },
  {
    value: "skyhy",
    percent_off: "0",
    link: "",
    title: "SkyHy by Hubble Contact Lenses",
    short_title: "SkyHy by Hubble",
    image: "",
    price: "29.99",
    sale_price: "9.99",
    cc_product_id: "skyhy",
    days: "30",
    quantity: 30
  }
]
