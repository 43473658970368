export const reviewsData = [
  {
    author: "Zulema J.",
    message: "“I’m glad I switched to Hubble. I put in a fresh pair every day. Never have to worry about reordering. So crisp and comfortable!!!”",
    reviewRating: 5
  },
  {
    author: "Jessi H.",
    message: "“This is my second month using Hubble and I’ll never go back! They are comfortable, affordable, and so healthy for my eyes!”",
    reviewRating: 5
  },
  {
    author: "Charlie K.",
    message: "“Omg! When I put them on I was extremely impressed. I’m in love. I cannot even remotely tell they are in. I will be a forever subscriber.”",
    reviewRating: 5
  },
  {
    author: "Anna H.",
    message: "“This is literally the only brand of contacts that works for me. I’ve tried all of the major brands and was ready to give up. I love my Hubble contacts <3.”",
    reviewRating: 5
  }
]